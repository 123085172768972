<template>
  <div>
    <b-overlay :show="state.loading">
      <b-card no-body class="p-1">
        <b-form class="d-flex flex-column" @submit.prevent="uploadImage">
          <b-form-group label="عنوان">
            <b-form-input
              type="text"
              placeholder="عنوان"
              v-model="uploadData.title"
            />
          </b-form-group>
          <b-form-group label="توضیحات">
            <b-form-input
              type="text"
              placeholder="توضیحات"
              v-model="uploadData.description"
            />
          </b-form-group>
          <b-form-group label="تصویر اسلایدر در سایز 750px * 400px">
            <b-form-file v-model="uploadData.image"
                         :state="Boolean(uploadData.file)"
                         placeholder="تصویر انتخابی خود را قرار دهید"
                         drop-placeholder="فایل را به اینجا بکشید"
            />
          </b-form-group>
          <b-button variant="primary" type="submit">ذخیره</b-button>
        </b-form>
      </b-card>
      <b-card no-body class="p-1">
        <b-row>
          <b-col class="mb-2" sm="6" md="4" v-for="slider in sliders" :key="slider.id">
            <b-card
              :img-src="slider.image"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border: 1px solid blue"
              class="h-100"
            >
              <b-card-text>
                {{ slider.description }}
              </b-card-text>
              <b-button @click.prevent="remove(slider.id)" variant="primary">حذف</b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  // BInputGroupPrepend,
  BOverlay,
  BFormFile,
  BCol,
  BRow,
  BCardText
} from 'bootstrap-vue'

export default {
  name: "Slider",
  components: {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    BFormInput,
    // BInputGroupPrepend,
    BCardText,
    BCol,
    BRow,
    BOverlay,
  },
  data() {
    return {
      uploadData: {
        image: null,
        description : '',
        title : ''
      },
      sliders: []
    }
  },
  methods : {
    async getData() {
      this.state.loading = true
      const res = await this.$axios.get('/sliders')
      this.sliders = res.data.data
    },
    async uploadImage() {
      this.state.loading = true
      const url = '/sliders'
      const formData = new FormData()
      formData.append('image', this.uploadData.image)
      formData.append('description', this.uploadData.description)
      formData.append('title', this.uploadData.title)

      await this.$axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      this.uploadData.image = null
      this.uploadData.description = ''
      this.uploadData.title = ''

      await this.getData()
    },
    async remove(id) {
      this.state.loading = true

      const url = '/sliders/' + id
      await this.$axios.delete(url)

      await  this.getData()
    }
  },
  async mounted() {
    await this.getData()
  }

}
</script>

<style scoped>

</style>
